import React, { createContext, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface Language {
  nativeName: string;
}

interface Languages {
  [key: string]: Language;
}

interface LanguageContextType {
  t: (key: string) => string;
  i18n: {
    changeLanguage: (lang: string) => void;
    language: string;
  };
  onClickLanguageChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  languages: Languages;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageContextProviderProps {
  children: ReactNode;
}

export const LanguageContextProvider = ({ children }: LanguageContextProviderProps) => {
  const languages: Languages = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' }
  };
  const { t, i18n } = useTranslation();

  const onClickLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value;
    i18n.changeLanguage(language); // Change the language
  };

  return (
    <LanguageContext.Provider value={{ t, i18n, onClickLanguageChange, languages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
