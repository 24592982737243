import React, { Suspense } from 'react';
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

const Home = React.lazy(() => import('./Pages/Home'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const Projects = React.lazy(() => import('./Pages/Projects/Projects'));
const Electrocalculadora = React.lazy(
  () => import('./Pages/Projects/Electrocalculadora/Electrocalculadora')
);
const Coronavacuna = React.lazy(() => import('./Pages/Projects/Coronavacuna/Coronavacuna'));
const Eurofuels = React.lazy(() => import('./Pages/Projects/Eurofuels/Eurofuels'));
const RoundPics = React.lazy(() => import('./Pages/Projects/RoundPics/RoundPics'));
const Temperaturas = React.lazy(() => import('./Pages/Projects/Temperaturas/Temperaturas'));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          {/* PROJECTS */}
          <Route path="/projects/electrocalculadora" element={<Electrocalculadora />} />
          <Route path="/projects/coronavacuna" element={<Coronavacuna />} />
          <Route path="/projects/eurofuels" element={<Eurofuels />} />
          <Route path="/projects/roundpics" element={<RoundPics />} />
          <Route path="/projects/temperaturas" element={<Temperaturas />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
