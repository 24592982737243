import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18next';

import { LanguageContextProvider } from './Translations/languageContext';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <App />
    </LanguageContextProvider>
  </React.StrictMode>
);
